﻿@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
:root {
  /*colors*/
  --green-strong: #2a6610;
  --green-light: #36a35b;
  --green-light-rgb: #{hexToRGB(#36a35b)};
  --green-mold: #006436;
  --green-op: #00846b;
  --green-ish: #eff8f6;
  --mold-green: #96cec1;
  --blue-cerulean: #003580;
  --blue-cerulean-rgb: #{hexToRGB(#003580)};
  --blue-navy: #0077cc;
  --blue-sapphire: #02214c;
  --blue-steel: #4c76b2;
  --blue-pale: #bad4f7;
  --blue-matte: #819bbf;
  --blue-solitude: #e9f0fa;
  --blue-ice: #f4f7fc;
  --yellow: #febb02;
  --yellow-rgb: #{hexToRGB(#febb02)};
  --yellow1: #febb02;
  --dark-yellow: #cd8900;
  --red: #ff4757;
  --red-rgb: #{hexToRGB(#ff4757)};
  --gray-dark: #383838;
  --gray-70: #707070;
  --gray-99: #999999;
  --gray-2c: #2c2c2c;
  --gray-26: #262626;
  --gray-bf: #bfbfbf;
  --gray-cc: #ccc;
  --gray-c1: #c1c1c1;
  --gray-cc-rgb: #{hexToRGB(#ccc)};
  --gray-ee: #eee;
  --gray-ed: #ededed;
  --gray-f6: #f6f6f6;
  --gray-e6: #e6e6e6;
  --gray-e6-rgb: #{hexToRGB(#e6e6e6)};
  --gray-f9: #f9f9f9;
  --gray-16: #161616;
  --gray-33: #333;
  --gray-33-rgb: #{hexToRGB(#333)};
  --gray-da: #dadada;
  --white: #fff;
  --white-rgb: #{hexToRGB(#fff)};
  --black: #000;
  --black-rgb: #{hexToRGB(#000)};
  --gray46: #464646;
  --gray46-rgb: #{hexToRGB(#464646)};
  --gray-f3: #f3f3f3;
  --gray-c6: #c6c6c6;
  --gray-c7: #7071730f;
  --gray-37: #37383c;
  --gray-37-rgb: #{hexToRGB(#37383c)};
  --light-color-1: #fde9cf;

  // new colors for redesign
  --color-1: #e74f13;
  --color-1-darker: #{darken(#e74f13, 10%)};
  --color-1-rgb: #{hexToRGB(#e74f13)};
  --color-2: #707173;
  --color-3: #1b80eb;
  --color-3-rgb: #{hexToRGB(#1b80eb)};
  --color-4: #00d16d;
  --color-4-rgb: #{hexToRGB(#00d16d)};
  --color-5: #6f6f6f;
  --color-5-rgb: #{hexToRGB(#6f6f6f)};
  --color-6: #ff4c0b;
  --color-7: #0bbeff;
  --color-8: #e84f15;
}
