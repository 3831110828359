﻿
$rootFontSize: 16px;

:root {  
    /*font-family*/ 
    --fontFamily: "IRANSans_FaNum";
    --fontFamilyEnNum: "IRANSans_EnNum";    
  
    /*font sizes*/ 
    --rootFontSize: #{$rootFontSize};
}