﻿/*font sizes*/
$rootFontSize: 16px;

/*font-icons*/
$icomoon-font-family: "BookingIcons" !default;
$icomoon-font-path: "../../Assets/Fonts/FontIcons" !default;

$icon-ship-line: "\e967";
$icon-refresh: "\e919";
$icon-phone: "\e914";
$icon-user-large: "\e904";
$icon-user: "\e90c";
$icon-user-circle: "\e90d";
$icon-home: "\e917";
$icon-news: "\e965";
$icon-office: "\e90b";
$icon-baggage: "\e966";
$icon-cip: "\e915";
$icon-train: "\e916";
$icon-backpack: "\e942";
$icon-hotel: "\e943";
$icon-insurance: "\e95f";
$icon-international-plane: "\e962";
$icon-plane: "\e963";
$icon-ship: "\e964";
$icon-down: "\e95e";
$icon-enter: "\e95d";
$icon-category: "\e95c";
$icon-minus: "\e95b";
$icon-website: "\e959";
$icon-mail: "\e95a";
$icon-door: "\e958";
$icon-help-display: "\e957";
$icon-left: "\e955";
$icon-down-1: "\e960";
$icon-up: "\e961";
$icon-right: "\e956";
$icon-download: "\e954";
$icon-clock: "\e910";
$icon-close: "\e933";
$icon-exchange: "\e937";
$icon-headphones: "\e940";
$icon-plane-line: "\e944";
$icon-star: "\e94a";
$icon-star-half: "\e94b";
$icon-whatsapp: "\e94c";
$icon-archive: "\e94d";
$icon-check-double: "\e94e";
$icon-document: "\e94f";
$icon-new: "\e950";
$icon-process: "\e951";
$icon-waiting: "\e952";
$icon-support: "\e949";
$icon-upload: "\e947";
$icon-plus: "\e948";
$icon-man: "\e945";
$icon-woman: "\e946";
$icon-dollar: "\e941";
$icon-airplane: "\e907";
$icon-exit: "\e938";
$icon-message: "\e93a";
$icon-Group-9355: "\e93b";
$icon-heart-box: "\e93c";
$icon-list: "\e93d";
$icon-person: "\e93e";
$icon-person-group: "\e93f";
$icon-moon: "\e936";
$icon-arrow-right: "\e934";
$icon-bx-heart: "\e901";
$icon-heart-fill: "\e902";
$icon-info: "\e900";
$icon-calendar-line: "\e903";
$icon-menu: "\e905";
$icon-credit-card: "\e906";
$icon-close-circle: "\e908";
$icon-edit: "\e909";
$icon-trash: "\e90a";
$icon-twitter: "\e90e";
$icon-linkedin: "\e90f";
$icon-instagram: "\e939";
$icon-facebook: "\e911";
$icon-telegram: "\e912";
$icon-search: "\e913";
$icon-carousel: "\e918";
$icon-map: "\e91a";
$icon-share: "\e91b";
$icon-k: "\e91c";
$icon-info-italic: "\e91d";
$icon-reply: "\e91e";
$icon-forward: "\e953";
$icon-like: "\e91f";
$icon-arrow-up: "\e920";
$icon-arrow-down: "\e921";
$icon-arrow-left: "\e932";
$icon-lock: "\e922";
$icon-check: "\e923";
$icon-bed: "\e924";
$icon-calendar: "\e925";
$icon-filter: "\e926";
$icon-sort: "\e927";
$icon-check-mark: "\e928";
$icon-family: "\e929";
$icon-calendar-selected: "\e92a";
$icon-info-circle-fill: "\e92b";
$icon-calendar-selected-first: "\e92c";
$icon-claws: "\e92d";
$icon-eye-off: "\e92e";
$icon-eye: "\e92f";
$icon-error: "\e930";
$icon-danger: "\e930";
$icon-success: "\e931";
$icon-warning: "\e935";
