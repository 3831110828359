﻿@import "../Variables/Main/variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3m20vv");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3m20vv#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3m20vv")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3m20vv")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3m20vv##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ship-line {
  &:before {
    content: $icon-ship-line; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-user-large {
  &:before {
    content: $icon-user-large;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-user-circle {
  &:before {
    content: $icon-user-circle;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-news {
  &:before {
    content: $icon-news;
  }
}
.icon-office {
  &:before {
    content: $icon-office;
  }
}
.icon-baggage {
  &:before {
    content: $icon-baggage;
  }
}
.icon-cip {
  &:before {
    content: $icon-cip;
  }
}
.icon-train {
  &:before {
    content: $icon-train;
  }
}
.icon-backpack {
  &:before {
    content: $icon-backpack;
  }
}
.icon-hotel {
  &:before {
    content: $icon-hotel;
  }
}
.icon-insurance {
  &:before {
    content: $icon-insurance;
  }
}
.icon-international-plane {
  &:before {
    content: $icon-international-plane;
  }
}
.icon-plane {
  &:before {
    content: $icon-plane;
  }
}
.icon-ship {
  &:before {
    content: $icon-ship;
  }
}
.icon-down {
  &:before {
    content: $icon-down;
  }
}
.icon-enter {
  &:before {
    content: $icon-enter;
  }
}
.icon-category {
  &:before {
    content: $icon-category;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-website {
  &:before {
    content: $icon-website;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-door {
  &:before {
    content: $icon-door;
  }
}
.icon-help-display {
  &:before {
    content: $icon-help-display;
  }
}
.icon-left {
  &:before {
    content: $icon-left;
  }
}
.icon-down-1 {
  &:before {
    content: $icon-down-1;
  }
}
.icon-up {
  &:before {
    content: $icon-up;
  }
}
.icon-right {
  &:before {
    content: $icon-right;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-exchange {
  &:before {
    content: $icon-exchange;
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}
.icon-plane-line {
  &:before {
    content: $icon-plane-line;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-check-double {
  &:before {
    content: $icon-check-double;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-new {
  &:before {
    content: $icon-new;
  }
}
.icon-process {
  &:before {
    content: $icon-process;
  }
}
.icon-waiting {
  &:before {
    content: $icon-waiting;
  }
}
.icon-support {
  &:before {
    content: $icon-support;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-man {
  &:before {
    content: $icon-man;
  }
}
.icon-woman {
  &:before {
    content: $icon-woman;
  }
}
.icon-dollar {
  &:before {
    content: $icon-dollar;
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-message {
  &:before {
    content: $icon-message;
  }
}
.icon-Group-9355 {
  &:before {
    content: $icon-Group-9355;
  }
}
.icon-heart-box {
  &:before {
    content: $icon-heart-box;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-person-group {
  &:before {
    content: $icon-person-group;
  }
}
.icon-moon {
  &:before {
    content: $icon-moon;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-bx-heart {
  &:before {
    content: $icon-bx-heart;
  }
}
.icon-heart-fill {
  &:before {
    content: $icon-heart-fill;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-calendar-line {
  &:before {
    content: $icon-calendar-line;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-close-circle {
  &:before {
    content: $icon-close-circle;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-telegram {
  &:before {
    content: $icon-telegram;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-carousel {
  &:before {
    content: $icon-carousel;
  }
}
.icon-map {
  &:before {
    content: $icon-map;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-k {
  &:before {
    content: $icon-k;
  }
}
.icon-info-italic {
  &:before {
    content: $icon-info-italic;
  }
}
.icon-reply {
  &:before {
    content: $icon-reply;
  }
}
.icon-forward {
  &:before {
    content: $icon-forward;
  }
}
.icon-like {
  &:before {
    content: $icon-like;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-bed {
  &:before {
    content: $icon-bed;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-sort {
  &:before {
    content: $icon-sort;
  }
}
.icon-check-mark {
  &:before {
    content: $icon-check-mark;
  }
}
.icon-family {
  &:before {
    content: $icon-family;
  }
}
.icon-calendar-selected {
  &:before {
    content: $icon-calendar-selected;
  }
}
.icon-info-circle-fill {
  &:before {
    content: $icon-info-circle-fill;
  }
}
.icon-calendar-selected-first {
  &:before {
    content: $icon-calendar-selected-first;
  }
}
.icon-claws {
  &:before {
    content: $icon-claws;
  }
}
.icon-eye-off {
  &:before {
    content: $icon-eye-off;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-danger {
  &:before {
    content: $icon-danger;
  }
}
.icon-success {
  &:before {
    content: $icon-success;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
